<template>
  <div>
    <CCard>
      <CCardHeader>
        <CIcon name="cil-search" /><strong> Smart Search </strong>
      </CCardHeader>
      <CCardBody>
        <CRow class="col-md-12 col-12">
          <CCol class="col-md-4 col-12">
            <CRow>
              <label class="">Date Range</label>
            </CRow>
            <CRow class="">
              <span
                class="form-control"
                @click="() => (date_modal = true)"
                style="cursor: pointer"
              >
                {{ changeDateFormate(startDate) }} <b>-</b>
                {{ changeDateFormate(endDate) }}
              </span>
              <CModal
                title="Select Date Range"
                :show.sync="date_modal"
                :centered="true"
                scrollable
                color="dark"
              >
                <date-range-picker
                  :from="date_data.endDate"
                  :to="date_data.startdate"
                  :future="false"
                  :panel="date_data.panel"
                  :showControls="false"
                  :presets="['custom', 'today', 'yesterday', 'last7days', 'last30days', 'last90days']"
                  :panels="[ 'range', 'week', 'month' ]"
                  @select="update"
                />
                <template #footer>
                  <CButton @click="date_modal = false" color="danger"
                    >Close</CButton
                  >
                </template>
              </CModal>
            </CRow>
          </CCol>
          <CCol class="col-md-3 col-12 ml-md-3">
            <CRow class="">
              <label class="">Campaign</label>
            </CRow>
            <CRow class="">
              <multiselect
                class="filter1"
                v-model="input.campid"
                placeholder="Campaign:"
                label="name"
                track-by="_id"
                :options="camp_list"
                :multiple="false"
                :taggable="false"
                :preselect-first="true"
                :close-on-select="true"
              >
              </multiselect>
            </CRow>
          </CCol>
          <CCol class="col-md-2 col-12 ml-md-3">
            <CRow class="">
              <label class="">Objectives</label>
            </CRow>
            <CRow>
              <multiselect
                class="filter1"
                track-by="value"
                label="text"
                v-model="input.objective"
                tag-placeholder="Add this as new tag"
                placeholder="Objectives:"
                :options="objectives_opt"
                :multiple="false"
                :taggable="false"
                :preselect-first="true"
                :close-on-select="true"
              >
              </multiselect>
            </CRow>
          </CCol>
          <CCol class="ml-md-3">
            <CRow><label class="p-2"></label></CRow>
            <CRow class="mt-2">
              <CButton
                class=""
                size=""
                type="submit"
                @click="onSubmit()"
                variant=""
                color="success"
                >Search</CButton
              >
            </CRow>
          </CCol>
        </CRow>
        <div class="card-header-actions pb-2">
          <small class="text-muted">
            <multiselect
              size="sm"
              :limit="2"
              :clear-on-select="false"
              :close-on-select="true"
              :multiple="false"
              :options="perPage"
              :preselect-first="false"
              :preserve-search="false"
              @input="checkPage()"
              placeholder="PerPage"
              v-model="tableperpage"
            ></multiselect
          ></small>
        </div>
        <div v-if="loading_data == true" class="p-5">
          <center>Loading . . .</center>
        </div>
        <div v-else>
          <!-- Main table element -->
          <CDataTable
            :items="items"
            :fields="fields"
            :dark="false"
            :items-per-page="tableperpage"
            sorter
            hover
            striped
            border
            small
            fixed
            column-filter
            light
          >
            <template #group_by="{ item }">
              <td class="col-md-10">
                <span v-for="i in item.group_by" :key="i">
                  <span class="multiselect__tag">
                     {{ allObj[i] }}
                  </span>
                </span>
                  <!-- {{ item.group_by.map((r)=> { return allObj[r] }).join(" ") }} -->
               
              </td>
            </template>
            <template #action="{ item }">
              <td>
                <!-- @click="Redirect(item)" -->
                <img
                  @click="OpenReportModal(item)"
                  src="img/logo/icn-2.png"
                  class="img-fluid ml-1"
                  title="Report"
                  style="cursor: pointer"
                />
              </td>
            </template>
          </CDataTable>
        </div>

      <CModal
        title="Report"
        :show.sync="report_modal"
        size="xl"
        :centered="true"
        scrollable
        color="dark"
      >
      <report-table :report_data='report_data'></report-table>
      <template #footer>
        <CButton @click="report_modal = false" color="danger">Close</CButton>
      </template>
      </CModal>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import CampaignControlService from "../../services/campaignControlService";
import Multiselect from "vue-multiselect";
import ReportTable from "../../components/ReportTable.vue"
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
  name: "VideoList",
  components: {
    Multiselect,
    ReportTable
  },
  data() {
    return {
      report_data:{},
      report_modal:false,
      date_data: { startdate: null, enddate: null, panel: null },
      date_modal: false,
      tableperpage: 20,
      perPage: [20, 50, 100],
      items: [],
      fields: [
        { key: "group_by", label: "GroupBy", sortable: true },
        { key: "action", label: "Action" },
      ],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      startDate: new Date(),
      endDate: new Date(),
      input: {
        edate: new Date(),
        sdate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
        campid: "",
        devicetype: "",
      },
      camp_list: [],
      objectives_opt: [
        { value: "impression", text: "Impression" },
        { value: "accepted", text: "Accepted" },
        { value: "rejected", text: "Rejected" },
        { value: "converted", text: "Conversion" },
        { value: "discarded", text: "Discarded" },
        { value: "render", text: "Render" },
      ],
      loading_data: false,
      groupby_opt: [
        { value: "campname", text: "Campaign" },
        { value: "offerid", text: "Offer" },
        { value: "tsid", text: "TrafficSource" },
        { value: "country", text: "Country" },
        { value: "os", text: "Operating System" },
        { value: "devicetype", text: "Device Type" },
        { value: "networktype", text: "Network Type" },
        // { value: "frausLvlForUser", text: "Fraud Level" }, 
        { value: "external_id", text: "External Id" },
        { value: "carrier", text: "Carrier" },
        { value: "qp_sub1", text: "Sub1" },
        { value: "qp_sub2", text: "Sub2" },
        { value: "qp_sub3", text: "Sub3" },
        { value: "qp_sub4", text: "Sub4" },
        { value: "qp_sub5", text: "Sub5" },
        { value: "qp_sub6", text: "Sub6" },
        { value: "qp_sub7", text: "Sub7" },
        { value: "qp_sub8", text: "Sub8" },
        { value: "qp_sub9", text: "Sub9" },
        { value: "qp_sub10", text: "Sub10" },
        { value: "qp_sub11", text: "Sub11" },
        { value: "qp_sub12", text: "Sub12" },
      ],
      allObj:{
        campname:'Campaign',
        offerid:'Offer',
        tsid:'Traffic Source',
        country:'Country',
        os:'Operating System',
        devicetype:'Device Type',
        networktype:'Network Type',
        frausLvlForUser:'Fraud Level',
        external_id:'External Id',
        carrier:'Carrier',
        qp_sub1:'Sub1',
        qp_sub2:'Sub2',
        qp_sub3:'Sub3',
        qp_sub4:'Sub4',
        qp_sub5:'Sub5',
        qp_sub6:'Sub6',
        qp_sub7:'Sub7',
        qp_sub8:'Sub8',
        qp_sub9:'Sub9',
        qp_sub10:'Sub10',
        qp_sub11:'Sub11',
        qp_sub12:'Sub12',
      },
      campaignopt: { value: "campname", text: "Campaign" },
    };
  },
  mounted() {
    if (
      (window.localStorage.getItem("user") &&
        window.localStorage.getItem("status") != "true") ||
      window.localStorage.getItem("iwitness") == "false"
    ) {
      this.$router.push("/");
    } else {
      if (window.localStorage.getItem("user") == "null") {
        alert("Please Enter User Name and Password");
        this.$router.push("Login");
      } else {
        this.report_modal = false
        this.dateFilter({
          startDate: new Date(this.startDate),
          endDate: new Date(this.endDate),
        });
        if (this.$root.$data.smart_search_payload) {
          this.input = this.$root.$data.smart_search_payload;
          this.startDate = this.$root.$data.smart_search_payload.sdate;
          this.endDate = this.$root.$data.smart_search_payload.edate;
          // this.dateFilter({startDate:new Date(this.startDate),endDate:new Date(this.endDate)});
          this.onSubmit();
        }
        this.getcampList();
      }
    }
  },
  methods: {
    changeDateFormate(d) {
    let dateToFormate
      if(typeof d != "string") {
        dateToFormate = new Date(d);
      } else {
        let dd = d.split("T")[0]
        dateToFormate = new Date(dd);
      } 
      return dateToFormate.toLocaleDateString("en-US", {
        day: "numeric",
        year: "numeric",
        month: "long",
      });
    },
    update(values) {
      this.date_data.startdate = values.from;
      this.date_data.enddate = values.to;
      this.date_data.panel = values.panel;
      this.dateFilter({
        startDate: new Date(values.from),
        endDate: new Date(values.to),
      });
      this.date_modal = false;
    },
    OpenReportModal(data) {
      var groupby_opt = this.groupby_opt.filter(function (item) {
        return data.group_by.includes(item.value);
      });
      var payload = {
        edate: this.input.edate,
        granularity: "daily",
        metrics: [this.input.objective],
        projection: groupby_opt,
        dimfilter: this.campaignopt,
        sortwith:this.input.objective,
        dimopt: [
          { text: this.input.campid.name, value: this.input.campid.name },
        ],
        sdate: this.input.sdate,
      };
      switch (this.input.objective.value) {
        case "impression":
          payload.metrics.push({
            value: "impressionRate",
            text: "Impression Rate",
          });
          break;
        case "accepted":
          payload.metrics.push({
            value: "acceptedRate",
            text: "Accepted Rate",
          });
          break;
        case "rejected":
          payload.metrics.push({
            value: "rejectedRate",
            text: "Rejected Rate",
          });
          break;
        case "converted":
          payload.metrics.push({
            value: "convertedRate",
            text: "Conversion Rate",
          });
          break;
        case "render":
          payload.metrics.push({ value: "renderRate", text: "Render Rate" });
          break;
      }
      payload.metrics.push({ value: "browse", text: "Click" });
      this.report_data = payload;
      this.$root.$data.report_action = 'smartsearch'
      this.report_modal = true;
      // this.$root.$data.smartsearch = payload;
      // this.$router.push("/report");
    },
    checkPage() {
      localStorage.setItem("smart_search", this.tableperpage);
      this.onSubmit();
    },
    async onSubmit() {
      this.items = [];
      if (this.validate()) {
        let payload = {
          camp_id: this.input.campid._id,
          sdate: this.input.sdate,
          edate: this.input.edate,
          objective: this.input.objective.value,
        };
        this.$root.$data.smart_search_payload = this.input;
        try {
          this.loading_data = true;
          let response = await CampaignControlService.smartsearch(payload);
          if (response.result) {
            var data = response.message;
            var d = [];
            var item = [];
            data.map((t) => {
              d.push(t);
            });
            d.map((l) => {
              let d = { group_by: l };
              item.push(d);
            });
            this.items = item;
          } else {
            Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
          }
          this.loading_data = false;
        } catch (e) {
          // alert("connection Error!");
        }
      } else {
        alert("Please Select Campaign");
      }
    },
    validate() {
      if (this.input.campid == "" || this.input.campid == null) {
        return false;
      }
      return true;
    },
    async getcampList() {
      var response = await CampaignControlService.get();
      var campaign = response.message.campaigns;
      campaign.map((t) => {
        this.camp_list.push({
          _id: t._id,
          name: t.campname,
        });
      });
    },
    dateFilter(data) {
      this.input.sdate = new Date(
        data.startDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.input.edate = new Date(
        data.endDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.startDate = data.startDate;
      this.endDate = data.endDate;
    },
  },
};
</script>


<style>
.filter1:focus-within .multiselect__content-wrapper {
  width: 300px !important;
}
</style>