<template>
  <div>
    <span v-if="user_status == 'true'">
    <CCard>
      <CCardBody class="text-center">
        <div v-if="loading == true">Loading . . .</div>
        <div v-else>
          <CRow>
            <CCol>
              <CRow class="col-md-12">
                <label>Total Rows : {{ totalRows }}</label>
              </CRow>
            </CCol>
            <CCol class="pb-2">
              <div class="card-header-actions">
                <small class="text-muted">
                  <multiselect
                    :limit="2"
                    :clear-on-select="false"
                    :close-on-select="true"
                    :multiple="false"
                    :options="perPage"
                    :preselect-first="false"
                    :preserve-search="false"
                    @input="checkPage()"
                    placeholder="PerPage"
                    v-model="tableperpage"
                  ></multiselect
                ></small>
              </div>
            </CCol>
          </CRow>
          <!-- <span  v-if="loading">Loading ...</span>
        <span v-else> -->
          <div class="table_div">
          <CDataTable
            :sorterValue.sync="sortDesc"
            :items="items"
            :fields="fields"
            :dark="false"
            :items-per-page="tableperpage"
            pagination
            :sorter='{ external: true, resetable: true }'
            hover
            striped
            border
            small
            fixed
            column-filter
          >
            <template #date="{ item }">
              <td style="width: 15%;">
                  <Tooltip
                :text="pieData1"
                :text2="pieData2"
                :pielabel1="pielabel1[0].data"
                :pielabel2="pielabel2[0].data"
                :showPie="showPie"
                >
                 <span @mouseover='getTooltipData(item)'
                >
                <div class="card-header-actions float-right">
                  <small class="ml-2" style="color:red;cursor:pointer;"> &#9432;</small>
                </div>
                {{ changeDateFormate(item.date) }}
                 </span>
                  </Tooltip>
              </td>
              <!-- {{item.date}} -->
            </template>
            <!-- column-filter -->
            <template #campname="{ item }"
              >
                <td :title="item.campname" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
              >
                 <span
                  class="link"
                  @click="openModel('CampURL', camp_list[item.campname])"
                >
                  {{ item.campname }}</span>
                  </td>

              </template
            >
            <template #external_id="{ item }">
              <td class="externalid">{{ item.external_id }}</td>
            </template>
            <template #offerid="{ item }"
              ><td>
                <span class="link" @click="getofferData(item.offerid)">{{
                  offer_name[item.offerid]
                }}</span>
              </td></template
            >

            <template #tsid="{ item }"
              ><td>
                <span class="link" @click="getTsData(item.tsid)">
                {{ traffic_source_name[item.tsid] }}
                </span>
                </td></template
            >

            <template #offerrev="{ item }"
              ><td>{{ item.offerrev.toFixed(2) }}</td></template
            >

            <template #camprev="{ item }"
              ><td>{{ item.camprev.toFixed(2) }}</td></template
            >
          <template #render-header="data">
           <span data-toggle="tooltip" title="page rendered on browser">Render</span>
          </template>
          <template #impression-header="data">
           <span data-toggle="tooltip" title="page viewed by user">Impression</span>
          </template>

          </CDataTable>
          </div>
        </div>
      </CCardBody>  
    </CCard>

    <!-- Show Model -->
    <CModal
      title="Campaign Url"
      :show.sync="camp_url"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
    <url-param :url_data="url_data"></url-param>
      
      <template #footer>
        <CButton @click="camp_url = false" color="danger">Close</CButton>
        <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
      </template>
    </CModal>
    </span>
    <span v-else>
      <CCard class="col-md-12">
         <h4 style="color:red;">Your Account is not activated</h4>
        <label>please contact to Manager</label>
      </CCard>
    </span>
  </div>
</template>


<script>
import moment from 'moment'
import DashboardService from "./../services/dashboardController";
import OfferService from "./../services/offerControlService";
import CampaignService from "./../services/campaignControlService";
import TsService from "./../services/trafficsourceControllservice";
import Config from "./../services/config";
import TimeZone from "./../services/timezones";
import Multiselect from "vue-multiselect";
import "@lazy-copilot/datetimepicker/dist/datetimepicker.css";
import { DateTimePicker } from "@lazy-copilot/datetimepicker";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import VueCsvDownloader from 'vue-csv-downloader';
import UrlParam from "../components/UrlParam.vue";
import Tooltip from "../components/ToolTip.vue";
var timezonelist = TimeZone
export default {
  name: "Dashboard",
  props: { report_data:{}}, 
  components: {
    Multiselect,
    DateTimePicker,
    VueCsvDownloader,
    UrlParam,
    TimeZone,
    Tooltip
  },
  computed:{
      pieData1(){
        return[
              {
          backgroundColor: [
            '#41B883',
            '#E46651',
            '#00D8FF',
            '#DD1B16'
          ],
          data: [0, 0, 0, 0]
        }
          ]
      },
      pieData2(){
        return[
              {
          label:"Security Checks",
          backgroundColor: [
            '#EC7063',
             '#AF7AC5',
             '#5499C7',
             '#1ABC9C',
             '#1ABC9C',
             '#F39C12',
             '#DC7633',
             '#5D6D7E',
          ],
          data: [0,0,0,0,0,0,0,0]
        }
          ]
      },
      pielabel1(){
        return[
          {
            data:["normal","sus","high","discard"]
          }
        ]
      },
      pielabel2(){
        return[
          {
            data:["suspiciouspattern","iframeTraffic","proxyTraffic","requestTempring","dangerIp","adultKeyword","nonPlayStoreApk","susDevConvDist"]
          }
        ]
      }
  },
  data() {
    return {
      reports_data:this.report_data,
      showPie:false,
      date_data:{startdate:null,enddate:null,panel:null},
      date_modal:false,
      sortDesc:{},
      all_data:[],
      filter: '',
      totalRows:0,
      filter_fields:{filter_1 :false,filter_2:false,filter_3:false,filter_4:false},
      tableperpage:20,
      perPage:[20,50,100,500,1000],
      isactive_revrisk:false,
      pageOptions: [10, 15],
      items: [],
      click_trigger: {},
      user: "",
      user_status: false,
      useremail: "",
      camp_list: [],
      offer_name: [],
      traffic_source_name: [],
      timezoneopt:timezonelist.map((t)=>{return {value:t.tzCode,text:t.label}}),
      filter_dash: {
        edate: new Date(),
        sdate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
        dim: {},
        granularity: "daily",
        projection: [],
        metrics: [],
        useremail: "",
        projectionopt:[],
        metricsopt:[
        { value: "browse", text: "Click" },
        { value: "render", text: "Render" },
        { value: "impression", text: "Impression" },
        { value: "accepted", text: "Accepted" },
        { value: "discarded", text: "Discarded" },
        { value: "rejected", text: "Rejected" },
        { value: "converted", text: "Converted" },],
        sortBy:'',
        sortAescDir:false,
        timezoneopt:{ text: '(GMT+00:00) London', value: 'Europe/London' }
      },
      startDate: new Date(),
      endDate: new Date(),
      click_risk: { blockedClick: null },
      conversion_risk: {},
      fields: [],
      dimfilter: "",
      dimfilter_f1 :"",
      dimfilter_f2 :"",
      dimfilter_f3 :"",
      dimfilter_f4 :"",
      projectionopt: [
        { value: "campname", text: "Campaign" },
        { value: "offerid", text: "Offer" },
        { value: "tsid", text: "TrafficSource" },
        { value: "country", text: "Country" },
        { value: "os", text: "Operating System" },
        { value: "devicetype", text: "Device Type" },
        { value: "networktype", text: "Network Type" },
        // { value: "frausLvlForUser", text: "Fraud Level" },
        { value: "external_id", text: "External Id" },
        { value: "carrier", text: "Carrier" },
        { value: "qp_sub1", text: "Sub1" },
        { value: "qp_sub2", text: "Sub2" },
        { value: "qp_sub3", text: "Sub3" },
        { value: "qp_sub4", text: "Sub4" },
        { value: "qp_sub5", text: "Sub5" },
        { value: "qp_sub6", text: "Sub6" },
        { value: "qp_sub7", text: "Sub7" },
        { value: "qp_sub8", text: "Sub8" },
        { value: "qp_sub9", text: "Sub9" },
        { value: "qp_sub10", text: "Sub10" },
        { value: "qp_sub11", text: "Sub11" },
        { value: "qp_sub12", text: "Sub12" },
      ],
      metricsopt: [
        { value: "browse", text: "Click" },
        { value: "render", text: "Render" },
        { value: "impression", text: "Impression" },
        { value: "accepted", text: "Accepted" },
        { value: "discarded", text: "Discarded" },
        { value: "rejected", text: "Rejected" },
        { value: "convertedRate", text: "Conversion Rate" },
        { value: "converted", text: "Converted" },
        { value: "harmfulApp", text: "Suspicious Pattern" },
        { value: "iframeTraffic", text: "Iframe Traffic" },
        { value: "proxyTraffic", text: "Proxy Traffic" },
        { value: "requestTempring", text: "Request Tempering" },
        // { value: "deviceAnomalies", text: "Device Anomalies" },
        { value: "dangerIp", text: "Danger IP" },
        { value: "adultKeyword", text: "Adult Keyword" },
        { value: "nonPlayStoreApk", text: "Non PlayStore Application" },
        { value: "susDevConvDist", text: "Suspicious Patterns" },
        { value: "offerrev", text: "Offer Revenue" },
        { value: "camprev", text: "Campaign Revenue" },
        { value: "event", text: "Event Count" },
        { value: "renderRate", text: "Render Rate" },
        { value: "impressionRate", text: "Impression Rate" },
        { value: "rejectedRate", text: "Rejected Rate" },
        { value: "acceptedRate", text: "Accepted Rate" },
      ],
      dimentionopt: [
        { value: "campname", text: "Campaign" },
        // { value: "camp_id", text: "Impression" },
        // { value: "userid", text: "User ID" },
        { value: "offerid", text: "Offer" },
        { value: "tsid", text: "Traffic Source" },
        { value: "country", text: "Country" },
        { value: "carrier", text: "Carrier" },
        { value: "os", text: "Os" },
        { value: "devicetype", text: "Device Type" },
        { value: "networktype", text: "Network Type" },
        // { value: "frausLvlForUser", text: "Fraud Level" },
        { value: "external_id", text: "External Id" },
        { value: "qp_sub1", text: "Sub1" },
        { value: "qp_sub2", text: "Sub2" },
        { value: "qp_sub3", text: "Sub3" },
        { value: "qp_sub4", text: "Sub4" },
        { value: "qp_sub5", text: "Sub5" },
        { value: "qp_sub6", text: "Sub6" },
        { value: "qp_sub7", text: "Sub7" },
        { value: "qp_sub8", text: "Sub8" },
        { value: "qp_sub9", text: "Sub9" },
        { value: "qp_sub10", text: "Sub10" },
        { value: "qp_sub11", text: "Sub11" },
        { value: "qp_sub12", text: "Sub12" },
      ],
      granularity_opt: ["daily", "hourly","monthly"],
      dimentionopt2: [],
      dimentionopt2_f1: [],
      dimentionopt2_f2: [],
      dimentionopt2_f3: [],
      dimentionopt2_f4: [],
      dimopt: [],
      dimopt_f1: [],
      dimopt_f2: [],
      dimopt_f3: [],
      dimopt_f4: [],
      url: "",
      zeroms_url: "",
      camp_url: false,
      url_param: {sub1: "sub1",sub2: "sub2",sub3: "sub3",sub4: "sub4",sub5: "sub5",sub6: "sub6",sub7: "sub7",
                  sub8: "sub8",sub9: "sub9",sub10: "sub10",sub11: "sub11",sub12: "sub12"},
      zeroms_url_param: {sub1: "sub1",sub2: "sub2",sub3: "sub3",sub4: "sub4",sub5: "sub5",sub6: "sub6",sub7: "sub7",
                        sub8: "sub8",sub9: "sub9",sub10: "sub10",sub11: "sub11",sub12: "sub12"},
      sdk_url_param: {sub1: "sub1",sub2: "sub2",sub3: "sub3",sub4: "sub4",sub5: "sub5",sub6: "sub6",sub7: "sub7",
                      sub8: "sub8",sub9: "sub9",sub10: "sub10",sub11: "sub11",sub12: "sub12"},
      collapseurl: false,
      collapsesdk: false,
      collapsezeroms: false,
      useremail:'',
      csvFields: [],
      change_date : false,
      loading:false,
      projectionopt_val:[],
      metricsopt_val:[],
      filter_count:0,
      sdk_url:'',
      sdk_data:'',
      url_data:{url:'',zeroms_url:'',sdk_data:'',sdk_url:''},
      show_form:false
    };
  },
  async mounted() {
    if(window.localStorage.getItem("user") == 'manager'){
      this.$router.push("/manager/userlist");
    }
    else{
    if (
      !window.localStorage.getItem("user") ||
      window.localStorage.getItem("user") == "null"
    ) {
      this.$router.push("/pages/login");
    } else {
      this.user = window.localStorage.getItem("user");
      this.user_status = window.localStorage.getItem("status");
      this.useremail = window.localStorage.getItem("email");
      this.filter_dash.useremail = this.useremail;
        this.reports_data = {}
      if(this.user_status == 'true'){
        this.dateFilter({startDate:new Date(this.startDate),endDate:new Date(this.endDate)});
      if(new Date(this.filter_dash.edate).getDate() != new Date().getDate()){
          this.startDate = new Date();
          this.endDate = new Date();
          this.dateFilter({startDate:new Date(this.startDate),endDate:new Date(this.endDate)});
        }
       try {
        let response = await DashboardService.camplist({
          useremail: this.useremail,
        });
        if (response.result) this.camp_list = response.message.campaign;
        this.offer_name = response.message.offer;
        this.traffic_source_name = response.message.trafficsource;
      } catch (e) {
        console.error("Error in getting camplist");
        console.log(e);
      }
      }
    }}
  },
   watch: {
    report_data() {
    this.filter_dash.projectionopt = this.report_data.projection;
    this.filter_dash.metricsopt = this.report_data.metrics;
    this.dimfilter = this.report_data.dimfilter;
    this.dimopt = this.report_data.dimopt;
    this.startDate = this.report_data.sdate;
    this.endDate  = this.report_data.edate;
    this.filter_dash.sdate = this.startDate
    this.filter_dash.edate = this.endDate
    this.runQuery();
    },
    sortDesc: function (val) {
      this.runQuery(val)
    },
    
  },
  methods: {
      async getTooltipData(item){
      this.showPie = false
      var filter = []
      if(this.dimfilter){
      if(this.dimfilter.value){
        filter.push(this.dimfilter)
      }
      if(this.dimfilter_f1.value){
        filter.push(this.dimfilter_f1)
      }
      if(this.dimfilter_f2.value){
        filter.push(this.dimfilter_f2)
      }
      if(this.dimfilter_f3.value){
        filter.push(this.dimfilter_f3)
      }
      if(this.dimfilter_f4.value){
        filter.push(this.dimfilter_f4)
      }}
      
      this.filter_dash.projectionopt.map(item => {
        filter.push(item)
      })
      let uniq = Object.values(filter.reduce((acc,cur)=>Object.assign(acc,{[cur.value]:cur}),{}))

      var data = []
        for(let i of uniq){
        data.push({
          name:i.value,
          value:item[i.value]
      })
        }
      let payload = {
            "date":item.date,
            "viewby":this.filter_dash.granularity,
            "groupby":data,
            "timezone":this.filter_dash.timezoneopt.value
            }

      let response = await DashboardService.fraudcount(payload);
       let a = response.message.graphData
       let a1 = response.message.checksData
        let key=Object.keys(a)
        let key1=Object.keys(a1)
        let b = []; 
        let b1 = []; 
        for(let i of key) {
        b.push(a[i])
        }
        for(let j of key1) {
        b1.push(a1[j])
        }
      this.pielabel1[0].data = key;
      this.pieData1[0].data = b;
      this.pielabel2[0].data = key1;
      this.pieData2[0].data = b1;
      this.showPie = true
    },
    changeDateFormate(d){


      var p = new Date(d);
      if(this.filter_dash.granularity == "monthly") {
         return moment.utc(d).format('MMM YYYY')
        
      } else if(this.filter_dash.granularity == "hourly") {
        return moment.utc(d).format('MMMM D, YYYY, HH A')
      } else {
        return moment.utc(d).format('MMMM D, YYYY')
      }
     
    },
    decodeHTMLEntities(text) {
    var entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['quot', '"']
    ];

    for (var i = 0, max = entities.length; i < max; ++i) 
        text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);

    return text;
    },
    checkPage(){
      localStorage.setItem("d_table", this.tableperpage);
    },
    async openModel(txt, id) {
      switch (txt) {
        case "CampURL":
          this.url = Config.campurl + id;
          let res = await CampaignService.getTid({campid: id});
          var tid = res.message.camp_eid
          this.zeroms_url = Config.zeromsurl + tid;
          this.sdk_url = Config.sdkurl + id;
          var sdk_data = "&lt;script src=&quot;"+this.sdk_url+"&quot; defer&gt;&lt;/script&gt;\n"+
                          "&lt;script&gt;\n"+
                            "document.addEventListener(&quot;rendered&quot;, function (e) {\n"+
                                "var event_id=e.detail;\n"+    
                            "/* your code to do xhr request to your own api, your own api will call fraudavoid api to validate the user. */\n"+
                            "})&lt;/script&gt;";
          this.sdk_data = this.decodeHTMLEntities(sdk_data)
          this.url_data.url = this.url
          this.url_data.zeroms_url = this.zeroms_url
          this.url_data.sdk_data = this.sdk_data
          this.url_data.sdk_url = this.sdk_url
          this.camp_url = true;
          break;
      }
    },
    async getofferData(id) {
      try {
        var data = { useremail: this.useremail, offerid: id };
        let response = await OfferService.get(data);
        this.$root.$data.offer = response.message[0];
        this.$root.$data.edit = true;
        this.$router.push("/offer/newoffer");
        // this.editoffer = true;
      } catch (e) {
        // Swal.fire({
        //   title: "Error!",
        //   text: "connection error",
        //   icon: "error",
        //   confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000
        // });
        // this.errorNotify("Error", "connection error", "danger");
        //   this.$router.push("Login");
      }
    },
    async getTsData(id) {
      try {
        var data = { useremail: this.useremail, offerid: id };
        let response = await TsService.tsget(data);
        this.$root.$data.traffic = response.message[0];
        this.$root.$data.edit = true;
        this.$router.push("/ts/newts");
        // this.editoffer = true;
      } catch (e) {
        // Swal.fire({
        //   title: "Error!",
        //   text: "connection error",
        //   icon: "error",
        //   confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000
        // });
        // this.errorNotify("Error", "connection error", "danger");
        //   this.$router.push("Login");
      }
    },
    dateFilter(data) {
      this.filter_dash.sdate = new Date(
        data.startDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.filter_dash.edate = new Date(
        data.endDate.getTime() - data.startDate.getTimezoneOffset() * 60000
      ).toISOString();
      this.startDate = data.startDate
      this.endDate = data.endDate
      // this.filter_dash.sdate = (new Date(data.startDate)).toISOString();
      // this.filter_dash.edate = (new Date(data.endDate)).toISOString()
    },
    runQuery: async function(sort) {
      // this.startDate = new Date(this.startDate)
      // this.endDate = new Date(this.endDate)
      this.loading = true
      this.change_date = false
      if(this.filter_dash.granularity == "hourly"){
        this.change_date = true
      }
      try {
        this.filter_dash.dim = {}
        this.pushTodim();
        if(this.filter_dash.projectionopt){
        this.filter_dash.projectionopt.map((m) => {
          this.filter_dash.projection.push(m.value);
          this.projectionopt_val.push({value:m.value, text: m.text})
        });}
        this.filter_dash.metrics = []
        this.filter_dash.metricsopt.map((m) => {
          this.filter_dash.metrics.push(m.value);
          this.metricsopt_val.push({value:m.value, text: m.text})
        });
        var response
        let b = {
            "asc": false,
            "column": this.report_data.sortwith.value
        }
        if(sort){
          this.filter_dash.sortBy = sort.column
          this.filter_dash.sortAescDir = sort.asc
        }
        else{
          this.filter_dash.sortBy = b.column
          this.filter_dash.sortAescDir = b.asc
        }
        response = await DashboardService.getdata(this.filter_dash);
        if(response.result){
        this.loading = false
        this.items = [];
        this.items = response.message.tableData;
        this.all_data = response.message.tableData;
        this.sortOptions();
        this.metricsopt_val=[]
        this.projectionopt_val = []
        this.totalRows = this.items.length;
        } else {
            Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
      } catch (e) {

        console.log("Error in getting dashboard data:");
        console.log(e);
      }
    },
     pushTodim() {
      // let cake = this.filter_dash.dim;
      if(this.dimfilter != null){
      if (this.dimfilter.value) {
        this.filter_dash.dim[this.dimfilter.value] = this.dimopt.map(r=>r.value);
      }
      if (this.dimfilter_f1.value) {
        this.filter_dash.dim[this.dimfilter_f1.value] = this.dimopt_f1.map(r=>r.value);
      }
      if (this.dimfilter_f2.value) {
        this.filter_dash.dim[this.dimfilter_f2.value] = this.dimopt_f2.map(r=>r.value);
      }
      if (this.dimfilter_f3.value) {
        this.filter_dash.dim[this.dimfilter_f3.value] = this.dimopt_f3.map(r=>r.value);
      }
      if (this.dimfilter_f4.value) {
        this.filter_dash.dim[this.dimfilter_f4.value] = this.dimopt_f4.map(r=>r.value);
      }
    }
    },
    capitalize(s)
    {
      let r = s[0].toUpperCase() + s.slice(1)
        return r.replace(/([a-z])([A-Z])/g, '$1 $2');
    },
    sortOptions() {
      // Create an options list from our fields
      let dim = Object.keys(this.filter_dash.dim).map((f) => {
       var d = this.dimentionopt.filter(t => t.value  == f)
        return { label: d[0].text, key: d[0].value, sortable: true ,_classes: 'dim'};
        // return { label: this.capitalize(f), key: f, sortable: true ,_classes: 'dim'};
      });
      
      // let projection = this.filter_dash.projectionopt.map((f) => {
      //   return { label: this.capitalize(f), key: f, sortable: true };
      // });
      let projection = this.projectionopt_val.map((f) => {
        return { label: this.capitalize(f.text), key: f.value, sortable: true , _classes: 'projection'};
      });
      // let metrics = this.filter_dash.metrics.map((f) => {
      //   return { label: this.capitalize(f), key: f, sortable: true , _classes: 'metrics'};
      // });
      let metrics = this.metricsopt_val.map((f) => {
        return { label: this.capitalize(f.text), key: f.value, sortable: true , _classes: 'metrics'};
      });
      this.fields = []
      this.fields = [
        { label: "Date", key: "date", sortable: true },
        ...dim,
        ...projection,
        ...metrics,
      ];
      this.csvFields = this.fields.map((e) => e.key);
      let b = [];
      for(let e of this.fields) if( b.findIndex(f => f.key == e.key) == -1) b.push(e)
      this.fields = b
      // let p = []
      // var f =  [...new Set(this.fields.map(item => p.push({label: item.label , key : item.key})))];
      return;
    },
  },
};
</script>
<style scoped>
.link {
  color: blue;
}
.link:hover {
  cursor: pointer;
  text-decoration: underline;
}
.f_button{
  margin-top: 35px;
}
</style>
<style>
.table_div{
  overflow: auto; height: 500px;
}
.table_div thead { position: sticky !important; top: 0 !important; z-index: 1 !important; }
table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#eee; }
.table-responsive{
  overflow: unset !important;
}
.externalid{
  max-width: 500px; 
}
.dim{
  /* max-width: 150px; */
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis; 
}
.metrics{
  /* max-width: 110px;
  white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis; 
}
.projection{
  /* max-width: 100px;
  white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis; 
}
.filter1:focus-within .multiselect__content-wrapper{
  width: 300px !important;
}
</style>
